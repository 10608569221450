import React, { useState } from "react";
import classes from "../scss/Header.module.scss";
import { NavButton } from "../ui/NavButton";
import khcLogo2 from "../assets/62.png";

const Header = () => {
    const [navMenuIsOpen, setNavMenuIsOpen] = useState(false);

    const toggleNavMenuHandler = () => {
        setNavMenuIsOpen(!navMenuIsOpen);

        console.log(!navMenuIsOpen);
    };

    const closeNav = () => {
        if (!navMenuIsOpen) {
            return;
        }
        setNavMenuIsOpen(!navMenuIsOpen);
    }

    return (
        <header className={classes.header}>
            <nav className={`${navMenuIsOpen ? classes.showmenu : ""}`}>
                <img src={khcLogo2} alt="knockhouse cafe logo"></img>
                <ul onClick={closeNav}>
                    <li>
                        <a href="#about">Home</a>
                    </li>
                    <li>
                        <a href="#menu">Menu</a>
                    </li>
                    {/* <li>
                        <a href="#promotions">Promotions</a>
                    </li> */}
                    <li>
                        <a href="#gallery">Gallery</a>
                    </li>
                    <li>
                        <a href="#team">Team</a>
                    </li>
                    <li>
                        <a href="#join">Join</a>
                    </li>
                    <li>
                        <a href="#contact">Contact</a>
                    </li>
                </ul>
            </nav>
            <NavButton
                onClick={toggleNavMenuHandler}
                className={`${navMenuIsOpen ? classes.close : ""}`}
            />
        </header>
    );
};

export default Header;
