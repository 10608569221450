import React from "react";
import JobForm from "../forms/JobForm";
import classes from "../scss/Join.module.scss";

const Join = () => {
    return (
        <section id="join" className={classes.container}>
            <div className={classes.join}>
                <h3>JOIN US</h3>

                <div className={classes.textGroup}>
                    <h3>FINANCE ASSISTANT</h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Nullam dapibus elit tellus, quis porta eros convallis
                        quis. Maecenas ac lacinia.
                    </p>
                </div>

                <div className={classes.textGroup}>
                    <h3>ADMIN EXECUTIVE</h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Nullam dapibus elit tellus, quis porta eros convallis
                        quis. Maecenas ac lacinia.
                    </p>
                </div>
            </div>
            <JobForm />
        </section>
    );
};

export default Join;
