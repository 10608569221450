import React, { Fragment } from "react";
import KHCMap from "../maps/KHCMap";
import classes from "../scss/Contact.module.scss";

import fbIcon from "../assets/facebook_icon.png";
import inIcon from "../assets/instagram_icon.png";
import ytIcon from "../assets/youtube_icon.png";
import { RiTreasureMapLine } from "react-icons/ri";

const Contact = () => {
    return (
        <Fragment>
            <section id="contact" className={classes.container}>
                <div className={classes.contact}>
                    <KHCMap className={classes.map} />
                    <div className={classes.info}>
                        <h3>CONTACT</h3>
                        <h4>Location:</h4>
                        <p>
                            185 Upper Thomson Rd
                            <br />
                            Yew Lian Park
                            <br />
                            Singapore 574333
                        </p>
                        <br />
                        <h4>Opening Hours:</h4>
                        <p>0900 to 2200</p>
                    </div>
                </div>
                
                <div className={classes.khcMap}>
                    <button type="button">
                        <a href="https://www.google.com/maps/place/185+Upper+Thomson+Rd,+Knockhouse+Cafe',+Singapore+574333/@1.3517375,103.8353504,18z/data=!4m2!3m1!1s0x31da173a48a5339d:0x7f430aab886f4e65">
                            OPEN MAP
                            <RiTreasureMapLine className={classes.mapIcon} />
                        </a>
                    </button>
                </div>
            </section>
            <div className={classes.icons}>
                <h3>Follow Us</h3>
                <div>
                    <a href="https://www.facebook.com/knockhousecafe/">
                        <img
                            src={fbIcon}
                            alt="facebook icon. Click to link to facebook page"
                        ></img>
                    </a>
                    <a href="https://www.instagram.com/knockhousecafe/">
                        <img
                            src={inIcon}
                            alt="instagram icon. Click to link to instagram page"
                        ></img>
                    </a>
                </div>
            </div>
        </Fragment>
    );
};

export default Contact;
