import React from "react";
import classes from "../scss/Menu.module.scss";

import kncmenuImg from "../assets/revamped_menu_v1.jpg";
import kncMenuView from "../assets/revamped_ menu_20220413.pdf";
import PrimaryButton from "../ui/PrimaryButton";

import { BsJournalArrowUp } from "react-icons/bs";

const Menu = () => {
    return (
        <section id="menu" className={classes.menuGroup}>
            <div className={classes.menu}>
                <img src={kncmenuImg} alt="knockhouse menu as backdrop"></img>
                <h4>
                    ALL
                    <br />
                    YOU
                    <br />
                    <span>NEED</span>
                    <br />
                    IS
                    <br />A<br />
                    GOOD
                    <br />
                    PLACE
                    <br />
                    TO
                    <br />
                    DINE
                </h4>
            </div>
            <PrimaryButton className={classes.button}>
                <a href={kncMenuView} target="_blank" rel="noopener">
                    <h4>VIEW MENU <BsJournalArrowUp className={classes.iconSize} /></h4>
                </a>
            </PrimaryButton>
        </section>
    );
};

export default Menu;
