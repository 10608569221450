import React from "react";
import aboutLogo from "../assets/kncLogoRound.png";

import classes from "../scss/About.module.scss";

const About = () => {
    return (
        <section id="about" className={classes.about}>
            <div className={classes.imgGroup}>
                <img src={aboutLogo} alt="knockhouse cafe circular logo"></img>
            </div>

            <div className={classes.textGroup}>
                <h3>ABOUT US</h3>
                <p>
                    <span>
                        Established in 2013, Knockhouse cafe is located at the
                        heart of Upper Thomson.
                        <br />
                        <br />
                        Previously known as Pacamara Singapore, we have
                        rebranded to Knockhouse Cafe since year 2020.
                        <br />
                        <br />
                        The word 'knock' is associated with the action and sound
                        of our barista team utilising the knock box during the
                        coffee preparation process.
                        <br />
                        <br />
                        Indulge in our own range of local flavour coffee beans,
                        especially curated for your palate all day.
                        <br />
                        <br />
                        We believe that part of this experience entails a
                        pleasant cafe experience. We strive to provide the best
                        cafe experiences for all our customers.
                        <br />
                        <br />
                        Our homely space which suggested the word 'house'
                        features a cosy setting that is suitable for all
                        occasions, hearty brunches, afternoon teas, dinners,
                        dates, events and celebrations.
                    </span>
                </p>
            </div>
        </section>
    );
};

export default About;
