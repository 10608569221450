import React, { Fragment } from "react";
import classes from "../scss/JobApplyModal.module.scss";
import Card from "./Card";

const JobApplyModal = (props) => {
    return (
        <Fragment>
            <div className={classes.backdrop} onClick={props.onConfirm} />
            <Card className={classes.modal}>
                <header className={classes.header}>
                    <h3>Thank You</h3>
                </header>
                <div className={classes.content}>
                    <p>
                        Thanks for applying job with us. Our Human Resource
                        shall get back to you the soonest possible time
                    </p>
                </div>
                <footer className={classes.action}>
                    <button className={classes.button} onClick={props.onConfirm} >Close</button>
                </footer>
            </Card>
        </Fragment>
    );
};

export default JobApplyModal;
