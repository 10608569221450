import React from "react";
import classes from "../scss/Footer.module.scss";

const Footer = () => {
    return (
        <footer className={classes.footer}>
            <span>Copyright © 2022 Knockhouse cafe. All right reserved</span>
        </footer>
    );
};

export default Footer;
