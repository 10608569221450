import React from "react";
import classes from "../scss/KHCMap.module.scss";

const KHCMap = (props) => {
    return (
        <div className={`${classes.googleMapCode} ${props.className}`}>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.708475110425!2d103.83317631534914!3d1.3514573619452936!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da173a48a5339d%3A0x7f430aab886f4e65!2sKnockhouse%20Cafe&#39;!5e0!3m2!1sen!2ssg!4v1649313710518!5m2!1sen!2ssg"
                width="600"
                height="450"
                frameborder="0"
                style={{ border: 0 }}
                allowfullscreen
                // aria-hidden="false"
                // tabindex="0"
            ></iframe>
        </div>
    );
};

export default KHCMap;
