import React, { Fragment, useState } from "react";
import Card from "../ui/Card";

import classes from "../scss/JobForm.module.scss";
import emailjs from "emailjs-com";
import JobApplyModal from "../ui/JobApplyModal";

const JobForm = (props) => {
    const [inputApplicantName, setInputApplicantName] = useState("");
    const [inputApplicantContact, setInputApplicantContact] = useState("");
    const [inputApplicantEmail, setInputApplicantEmail] = useState("");
    const [selectApplicantPost, setSelectApplicantPost] = useState(0);

    const [submited, setSubmitted] = useState(false);

    const addApplicantHandler = (event) => {
        event.preventDefault();
        if (
            inputApplicantName.trim().length === 0 ||
            inputApplicantContact.trim().length === 0 ||
            inputApplicantEmail.trim().length === 0 ||
            selectApplicantPost == 0
        )
            return;

        emailjs
            .sendForm(
                "service_k7q6h7a",
                "knc_jobform",
                event.target,
                "EdDgkCzqhG_b5ILN3"
            )
            .then(
                (result) => {
                    console.log(result.text);
                    setSubmitted(true);
                },
                (error) => {
                    console.log(error.text);
                    setSubmitted(false);
                }
            );
        event.target.reset();
    };

    const applicantNameChangeHandler = (event) => {
        setInputApplicantName(event.target.value);
    };
    const applicantContactChangeHandler = (event) => {
        setInputApplicantContact(event.target.value);
    };
    const applicantEmailChangeHandler = (event) => {
        setInputApplicantEmail(event.target.value);
    };
    const applicantPositionChangeHandler = (event) => {
        setSelectApplicantPost(event.target.value);
    };

    const closeJobModal = () => {
        setSubmitted(false);
    }

    return (
        <Fragment>
        {submited && ( <JobApplyModal onConfirm={closeJobModal}/>)}
            <Card className={`${classes.input} ${props.className}`}>
                <h4>APPLY NOW</h4>

                <form onSubmit={addApplicantHandler}>
                    <label htmlFor="applicantName">Name</label>
                    <input
                        id="applicantName"
                        type="text"
                        onChange={applicantNameChangeHandler}
                        name="name"
                    ></input>

                    <label htmlFor="applicantContact">Contact</label>
                    <input
                        id="applicantContact"
                        type="tel"
                        onChange={applicantContactChangeHandler}
                        name="contact"
                    ></input>

                    <label htmlFor="applicantEmail">Email</label>
                    <input
                        id="applicantEmail"
                        type="email"
                        onChange={applicantEmailChangeHandler}
                        name="email"
                    ></input>

                    <label htmlFor="applicantPosition">Position</label>
                    <select
                        id="applicantPosition"
                        onChange={applicantPositionChangeHandler}
                        name="jobapply"
                    >
                        <option value="0">Please choose position</option>
                        <option value="Finance Assistant">
                            Finance Assistant
                        </option>
                        <option value="Admin Executive">Admin Executive</option>
                    </select>
                    <button className={classes.button} type="submit">
                        submit
                    </button>
                </form>
            </Card>
        </Fragment>
    );
};

export default JobForm;
