import React from "react";
import classes from "../scss/PrimaryButton.module.scss";

const PrimaryButton = (props) => {
    return (
        <button
            type={props.type || "button"}
            onClick={props.onClick}
            className={`${classes.primaryButton} ${props.className}`}
        >
            {props.children}
        </button>
    );
};

export default PrimaryButton;
