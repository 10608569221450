// import React, { useState } from "react";
// import classes from "../scss/Gallery.module.scss";
// import { ImgSliderData } from "./ImgSliderData";
// import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";

// const Gallery = ({ slides }) => {
//     const [current, setCurrent] = useState(0);
//     const length = slides.length;

//     const nextSlide = () => {
//         setCurrent(current === length - 1 ? 0 : current + 1);
//     };

//     const prevSlide = () => {
//         setCurrent(current === 0 ? length - 1 : current - 1);
//     };

//     // console.log(current);

//     if (!Array.isArray(slides) || length <= 0) {
//         return null;
//     }

//     return (
//         <section className={classes.gallery}>
//             <FaArrowAltCircleLeft
//                 className={classes.left_arrow}
//                 onClick={prevSlide}
//             />
//             <FaArrowAltCircleRight
//                 className={classes.right_arrow}
//                 onClick={nextSlide}
//             />
//             {ImgSliderData.map((slide, index) => {
//                 return (
//                     <div
//                         className={`${
//                             index === current ? classes.active : ""
//                         }`}
//                         key={index}
//                     >
//                         {index === current && (
//                             <img
//                                 src={slide.img}
//                                 alt={slide.alt}
//                                 className={classes.image}
//                             />
//                         )}
//                     </div>
//                 );
//             })}
//         </section>
//     );
// };

// export default Gallery;

import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

import img1 from "../assets/sliderimg/img01.jpeg";
import img2 from "../assets/sliderimg/img02.jpeg";
import classes from "../scss/Gallery.module.scss";

const Gallery = () => {
    return (
        <section id="gallery">
        <h3 className={classes.title}>GALLERY</h3>
            <Splide
                className={classes.gallery}
                options={{
                    type: "loop",
                    gap: "5rem",
                    padding: "20%",
                    height: 600,
                    breakpoints: {
                        768: {
                            padding: 0,
                            height: 600,
                            width: "auto",
                        },
                    },
                }}
                aria-label="My Favorite Images"
            >
                <SplideSlide>
                    <img src={img1} alt="Image 1" className={classes.image} />
                </SplideSlide>
                <SplideSlide>
                    <img src={img2} alt="Image 2" className={classes.image} />
                </SplideSlide>
                <SplideSlide>
                    <img src={img1} alt="Image 1" className={classes.image} />
                </SplideSlide>
                <SplideSlide>
                    <img src={img2} alt="Image 2" className={classes.image} />
                </SplideSlide>
                <SplideSlide>
                    <img src={img1} alt="Image 1" className={classes.image} />
                </SplideSlide>
                <SplideSlide>
                    <img src={img2} alt="Image 2" className={classes.image} />
                </SplideSlide>
            </Splide>
        </section>
    );
};

export default Gallery;
