import React from "react";
import classes from "../scss/Team.module.scss";

import ChefMichael from "../assets/ChefMichael.jpeg";

const Team = () => {
    return (
        <section id="team" className={classes.bg}>
            <div className={classes.team}>
                <h3> OUR TEAM</h3>
                <div className={classes.contentGroup}>
                    <div className={classes.imgGroup}>
                        <img src={ChefMichael}></img>
                    </div>
                    <div className={classes.textGroup}>
                        <h3>Michael Teng (Head Chef)</h3>
                        <p>
                            Starting as a training cook since 1988, Michael has
                            now been a chef for 32 years and counting.
                            <br />
                            <br />
                            He has travelled for work attachments in other
                            countries such as Japan and China, gaining a lot of
                            broad experience there.
                            <br />
                            <br />
                            Before joining Knockhouse Cafe as our Head Chef,
                            Michael worked a few years in country clubs such as
                            The Singapore Recreation Club, Tanah Merah Country
                            Club and Warren Golf and Country Club.
                            <br />
                            <br />
                            He also has an extensive experience working in
                            Japanese restaurants, bringing his excellent
                            expertise with him when he joined us and turning
                            Knockhouse Cafe from your usual western brunch place
                            to a fusion cafe serving western and japanese
                            inspired delicacies.
                            <br />
                            <br />
                            In 2012, Chef Michael took part in the FHA 2012
                            Culinary Challenge (Individual Category) and emerged
                            as the Silver Medallist. Wow!
                            <br />
                            <br />
                            When asked about his passion and what he loves about
                            being a chef, he said that seeing his customers
                            enjoy the food he made gives him so much joy and
                            pride. (so make sure to leave good reviews!)
                            <br />
                            <br />
                            P.S: Chef Michael's favourite dishes are the
                            Mentaiko Carbonara and Char Siew Ramen!
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Team;
