import React from 'react';
import classes from '../scss/NavButton.module.scss'

export const NavButton = (props) => {

    return (
        <button onClick={props.onClick} className={`${classes.navButton} ${props.className}`}>{props.children}
            <span></span>
            <span></span>
            <span></span>
        </button>
    );
};

export const NavButtonA = (props) => {

    return (
        <button onClick={props.onClick} className={`${classes.navButtonA} ${props.className}`}>{props.children}
            <span></span>
            <span></span>
            <span></span>
        </button>
    );
};