import React, { Fragment } from "react";
import classes from "./components/scss/App.module.scss";

import Header from "./components/layout/Header";
import About from "./components/layout/About";
import Menu from "./components/layout/Menu";
import Footer from "./components/layout/Footer";
// import Promotions from "./components/layout/Promotions";
import Team from "./components/layout/Team";
import Join from "./components/layout/Join";
import Contact from "./components/layout/Contact";
import Gallery from "./components/layout/Gallery";


const App = () => {
    return (
        <Fragment>
            <Header />
            <main className={classes.main}>
                <About />
                <Menu />
                {/* <Promotions /> */}
                <Gallery />
                <Team />
                <Join />
                <Contact />
            </main>
            <Footer />
        </Fragment>
    );
};

export default App;
